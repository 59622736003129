function hoverInImage(el) {
	$(el).prev().find('.magazine-article-image').css("opacity", "0.8");
}

function hoverOutImage(el) {
	$(el).prev().find('.magazine-article-image').css("opacity", "1");
}

jQuery(document).ready(function($) {
	/* Mobile menu */
	var $mobileMenu = $(".mobile-navigation-menu");
	var $mobileMenuTrigger = $(".menu-button.w-nav-button");

	$mobileMenu.mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("w--open");

		return false;
	});

	API.bind('closed', function() {
		$mobileMenuTrigger.removeClass("w--open");
	});

	/* FitVids */
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-page-wrapper").fitVids({ ignore: "nofit" });
	}

	/* Accessible menu */
	// Add dropdown class to main nav items
	$(".main-nav-dd-widget:has('.container-wrapper')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .main-nav-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".main-nav-dd-widget").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".main-nav-dd-widget").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	$(".accesible-navigation-menu").accessibleMenu();

	// STICKY CATEGORY NAV
	window.onscroll = function() {
		if (window.pageYOffset >= sticky) {
			navbar.classList.add("sticky");
			contentSection.classList.add("sticky");
		}
		else {
			navbar.classList.remove("sticky");
			contentSection.classList.remove("sticky");
		}
	};
	var navbar = document.getElementsByClassName('mag-navbar-widget')[0];
	var contentSection = document.getElementsByClassName('mag-home-content-section').length > 0 ? document.getElementsByClassName('mag-home-content-section')[0] : document.getElementsByClassName('inside-page-wrapper')[0];
	var sticky = document.getElementsByClassName('hero-section').length > 0 ? navbar.offsetTop + 130 : navbar.offsetTop;

	/* eslint-diable */
	/* Webflow interactions */
	/**
	 * ----------------------------------------------------------------------
	 * Webflow: Interactions: Init
	 */
	Webflow.require('ix').init([
		{slug: "feature-hover",name: "Feature Hover",value: {style: {},triggers: [{type: "hover",selector: ".mag-feature-image-wrap-1",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{wait: "100ms"},{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "medium-feature-hover",name: "Medium Feature Hover",value: {style: {},triggers: [{type: "hover",selector: ".mag-item-1-image",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "medium-feature-hover-2",name: "Medium Feature Hover 2",value: {style: {},triggers: [{type: "hover",selector: ".mag-item-2-image",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "medium-feature-hover-3",name: "Medium Feature Hover 3",value: {style: {},triggers: [{type: "hover",selector: ".mag-item-3-image",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "medium-feature-hover-4",name: "Medium Feature Hover 4",value: {style: {},triggers: [{type: "hover",selector: ".mag-item-4-image",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "medium-feature-hover-5",name: "Medium Feature Hover 5",value: {style: {},triggers: [{type: "hover",selector: ".mag-item-5-image",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "medium-feature-hover-6",name: "Medium Feature Hover 6",value: {style: {},triggers: [{type: "hover",selector: ".mag-item-6-image",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "medium-feature-hover-7",name: "Medium Feature Hover 7",value: {style: {},triggers: [{type: "hover",selector: ".mag-item-7-image",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "medium-feature-hover-8",name: "Medium Feature Hover 8",value: {style: {},triggers: [{type: "hover",selector: ".mag-item-8-image",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "medium-feature-hover-9",name: "Medium Feature Hover 9",value: {style: {},triggers: [{type: "hover",selector: ".mag-item-9-image",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "medium-feature-hover-10",name: "Medium Feature Hover 10",value: {style: {},triggers: [{type: "hover",selector: ".mag-item-10-image",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "horiz-feature-hover-1",name: "Horiz Feature Hover 1",value: {style: {},triggers: [{type: "hover",selector: ".mag-horiz-image-wrap-1",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{wait: "100ms"},{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "horiz-feature-hover-2",name: "Horiz Feature Hover 2",value: {style: {},triggers: [{type: "hover",selector: ".mag-horiz-image-wrap-2",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{wait: "100ms"},{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "horiz-feature-hover-3",name: "Horiz Feature Hover 3",value: {style: {},triggers: [{type: "hover",selector: ".mag-horiz-image-wrap-3",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{wait: "100ms"},{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "right-col-hover-1",name: "Right Col Hover 1",value: {style: {},triggers: [{type: "hover",selector: ".event-image-1",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{wait: "100ms"},{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "right-col-hover-2",name: "Right Col Hover 2",value: {style: {},triggers: [{type: "hover",selector: ".event-image-2",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{wait: "100ms"},{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "right-col-hover-3",name: "Right Col Hover 3",value: {style: {},triggers: [{type: "hover",selector: ".event-image-3",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{wait: "100ms"},{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "right-col-hover-4",name: "Right Col Hover 4",value: {style: {},triggers: [{type: "hover",selector: ".event-image-4",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{wait: "100ms"},{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "right-col-hover-5",name: "Right Col Hover 5",value: {style: {},triggers: [{type: "hover",selector: ".mag-right-col-image-wrap-5",stepsA: [{opacity: 0.8,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}],stepsB: [{wait: "100ms"},{opacity: 1,transition: "all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s"}]}]}},
		{slug: "event-category-reveal",name: "Event Category  Reveal",value: {style: {},triggers: [{type: "hover",selector: ".event-cat",descend: true,stepsA: [{opacity: 1,height: "auto",transition: "height 200ms ease 0, opacity 200ms ease 0"}],stepsB: [{opacity: 0,height: "0px",transition: "opacity 200ms ease 0, height 200ms ease 0"}]}]}},
		{slug: "search-cover",name: "Search Cover",value: {style: {},triggers: [{type: "click",selector: ".search-container-cover",stepsA: [{height: "100vh",transition: "height 300ms ease 0"}],stepsB: []}]}},
		{slug: "hero-load",name: "Hero Load",value: {style: {opacity: 0.6000000000000003},triggers: [{type: "load",offsetBot: "10%",stepsA: [{wait: "100ms"},{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 1500ms ease-out-quad 0"}],stepsB: []}]}},
		{slug: "hero-load-2",name: "Hero Load 2",value: {style: {opacity: 0,x: "0px",y: "-300px",z: "0px"},triggers: [{type: "load",offsetBot: "10%",stepsA: [{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 200 ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "hero-load-3",name: "Hero Load 3",value: {style: {opacity: 0,x: "0px",y: "-300px",z: "0px"},triggers: [{type: "load",offsetBot: "10%",stepsA: [{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 200 ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "content-reveal-1",name: "Content Reveal 1",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "load",offsetBot: "10%",stepsA: [{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "content-reveal-2",name: "Content Reveal 2",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "load",offsetBot: "10%",stepsA: [{wait: "100ms"},{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "content-reveal-3",name: "Content Reveal 3",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "load",offsetBot: "10%",stepsA: [{wait: "200ms"},{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "content-reveal-4",name: "Content Reveal 4",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "load",offsetBot: "10%",stepsA: [{wait: "300ms"},{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "content-reveal-5",name: "Content Reveal 5",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "load",offsetBot: "0%",stepsA: [{wait: "400ms"},{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "content-reveal-6",name: "Content Reveal 6",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "load",offsetBot: "0%",stepsA: [{wait: "500ms"},{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "content-reveal-7",name: "Content Reveal 7",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "load",offsetBot: "0%",stepsA: [{wait: "700ms"},{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "content-reveal-8",name: "Content Reveal 8",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "load",offsetBot: "0%",stepsA: [{wait: "800ms"},{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "content-reveal-9",name: "Content Reveal 9",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "load",offsetBot: "0%",stepsA: [{wait: "1000ms"},{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "scroll-reveal-1",name: "Scroll Reveal 1",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "scroll",offsetBot: "0%",stepsA: [{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "scroll-reveal-2",name: "Scroll Reveal 2",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "scroll",offsetBot: "0%",stepsA: [{wait: "100ms"},{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "hero-caption-hover",name: "Hero Caption Hover",value: {style: {},triggers: [{type: "hover",selector: ".big-feature-image-wrap",preserve3d: true,stepsA: [{opacity: 1,transition: "opacity 1000ms ease 0, transform 1000ms ease 0",scaleX: 1.05,scaleY: 1.05,scaleZ: 1}],stepsB: [{opacity: 0.6000000000000006,transition: "transform 1000ms ease 0, opacity 1000ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}]}]}},
		{slug: "slide-reveal",name: "Slide Reveal",value: {style: {opacity: 0,x: "300px",y: "0px",z: "0px"},triggers: [{type: "slider",stepsA: [{opacity: 1,transition: "transform 700ms ease-out-quad 0, opacity 900ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: [{wait: "1000ms"},{opacity: 0,x: "300px",y: "0px",z: "0px"}]}]}},
		{slug: "slide-header-reveal",name: "Slide Header Reveal",value: {style: {opacity: 0,x: "300px",y: "0px",z: "0px"},triggers: [{type: "slider",stepsA: [{wait: "150ms"},{opacity: 1,transition: "transform 700ms ease-out-quad 0, opacity 900ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: [{wait: "1000ms"},{opacity: 0,x: "300px",y: "0px",z: "0px"}]}]}},
		{slug: "slide-caption-reveal",name: "Slide Caption Reveal",value: {style: {opacity: 0,x: "300px",y: "0px",z: "0px"},triggers: [{type: "slider",stepsA: [{wait: "250ms"},{opacity: 1,transition: "transform 700ms ease-out-quad 0, opacity 900ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: [{wait: "1000ms"},{opacity: 0,x: "300px",y: "0px",z: "0px"}]}]}},
		{slug: "reveal-all-flights",name: "Reveal All Flights",value: {style: {},triggers: [{type: "hover",selector: ".find-flight-dd",stepsA: [{opacity: 1,height: "auto",transition: "height 200 ease 0, opacity 200 ease 0"}],stepsB: [{wait: "2000ms"},{opacity: 0,height: "0px",transition: "opacity 200 ease 0, height 200 ease 0"}]}]}},
		{slug: "load-icon-rotate",name: "Load Icon Rotate",value: {style: {},triggers: [{type: "hover",selector: ".load-more-icon-link",preserve3d: true,stepsA: [{opacity: 1,transition: "transform 800ms ease 0, opacity 200 ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "270deg"}],stepsB: [{opacity: 0.6000000000000003,transition: "transform 400ms ease 0, opacity 200 ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "0deg"}]},{type: "hover",selector: ".load-more-link",stepsA: [{opacity: 1,transition: "opacity 200 ease 0"}],stepsB: [{opacity: 0.6000000000000003,transition: "opacity 200 ease 0"}]}]}},
		{slug: "reveal-all-flights-2",name: "Reveal All Flights 2",value: {style: {},triggers: [{type: "click",selector: ".flight-results-outer-wrap",stepsA: [{wait: "500ms"},{display: "block"},{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "height 200 ease 0, opacity 200 ease 0"}],stepsB: []}]}},
		{slug: "hover-out-flight-tool",name: "Hover Out Flight Tool",value: {style: {},triggers: [{type: "hover",selector: ".find-flight-dd",stepsA: [],stepsB: [{opacity: 0,height: "0px",transition: "height 200 ease 0, opacity 200 ease 0"}]},{type: "hover",selector: ".flight-results-outer-wrap",stepsA: [],stepsB: [{opacity: 0,height: "0px",transition: "height 200 ease 0, opacity 200 ease 0"},{wait: "200ms"},{display: "none"}]}]}},
		{slug: "hover-out-flight-tool-2",name: "Hover Out Flight Tool 2",value: {style: {},triggers: [{type: "hover",selector: ".mini-flight-results-outer-wrap",stepsA: [],stepsB: [{opacity: 0,height: "0px",transition: "height 200 ease 0, opacity 200 ease 0"},{wait: "200ms"},{display: "none"}]}]}},
		{slug: "search-push-down",name: "Search Push Down",value: {style: {},triggers: [{type: "click",selector: ".header-search-container",stepsA: [{height: "75px",transition: "height 300ms ease 0"}],stepsB: [{height: "0px",transition: "height 300ms ease 0"}]}]}},
		{slug: "search-dd-smooth-open",name: "Search DD Smooth Open",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "opacity 350ms ease 0, height 350ms ease 0"}],stepsB: [{height: "0px",transition: "height 100ms ease 0, opacity 100ms ease 0"}]}]}},
		{slug: "close-search",name: "Close Search",value: {style: {},triggers: [{type: "click",selector: ".search-container-cover",stepsA: [{height: "0%",transition: "height 300ms ease 0"}],stepsB: []}]}},
		{slug: "insta-load",name: "Insta Load",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "insta-load-2",name: "Insta Load 2",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "scroll",stepsA: [{wait: "100ms"},{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "insta-load-3",name: "Insta Load 3",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "scroll",stepsA: [{wait: "150ms"},{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-2-cb-1-reveal",name: "Row 2 CB 1 Reveal",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-2-cb2-reveal",name: "Row 2 CB2 Reveal ",value: {style: {opacity: 0,x: "0px",y: "100px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{opacity: 1,transition: "transform 550ms ease-out-quad 0, opacity 650ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "cb2-reveal",name: "CB2 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",preload: true,stepsA: [{wait: "1000ms"},{opacity: 1,transition: "transform 750ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "ken-burnsslideshow",name: "Ken BurnsSlideshow",value: {style: {},triggers: [{type: "slider",loopA: true,loopB: true,stepsA: [{wait: "15000ms",transition: "transform 15000ms ease 0",scaleX: 1.2,scaleY: 1.2,scaleZ: 1,x: "0px",y: "0px",z: "0px"}],stepsB: [{wait: "20000ms",transition: "transform 20000ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}]}]}},
		{slug: "dropdown-hover",name: "Dropdown Hover",value: {style: {},triggers: [{type: "hover",selector: ".dropdown-list",descend: true,stepsA: [{display: "block",opacity: 1,height: "auto",transition: "opacity 300ms ease 0, height 300ms ease 0"}],stepsB: [{display: "none",opacity: 0,height: "0px",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}]},{type: "hover",selector: ".nav-dd-triangle",descend: true,stepsA: [{display: "block"}],stepsB: [{display: "none"}]}]}},
		{slug: "feature-slide-stage",name: "Feature Slide Stage",value: {style: {scaleX: 0.9400000000000006,scaleY: 0.9400000000000006,scaleZ: 1},triggers: [{type: "slider",stepsA: [{opacity: 1,transition: "transform 300ms ease 0, opacity 300ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: [{opacity: 0.5,transition: "transform 300ms ease 0, opacity 300ms ease 0",scaleX: 0.9400000000000006,scaleY: 0.9400000000000006,scaleZ: 1}]}]}},
		{slug: "feature-image",name: "Feature Image",value: {style: {opacity: 0.5,scaleX: 1.5,scaleY: 1.5,scaleZ: 1},triggers: [{type: "slider",stepsA: [{wait: "500ms"},{opacity: 1,transition: "transform 2000ms ease-out-circ 0, opacity 200 ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: [{transition: "transform 300ms ease 0",scaleX: 1.5,scaleY: 1.5,scaleZ: 1}]}]}},
		{slug: "spinner",name: "Spinner",value: {style: {},triggers: [{type: "click",selector: ".spinner-country-wrap",preserve3d: true,stepsA: [{transition: "transform 50ms ease 0",rotateX: "60deg",rotateY: "0deg",rotateZ: "0deg"},{transition: "transform 50ms ease 0",rotateX: "120deg",rotateY: "0deg",rotateZ: "0deg"},{transition: "transform 50ms ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "0deg"},{transition: "transform 50ms ease 0",rotateX: "60deg",rotateY: "0deg",rotateZ: "0deg"},{transition: "transform 50ms ease 0",rotateX: "120deg",rotateY: "0deg",rotateZ: "0deg"},{transition: "transform 50ms ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "0deg"},{transition: "transform 50ms ease 0",rotateX: "60deg",rotateY: "0deg",rotateZ: "0deg"},{transition: "transform 50ms ease 0",rotateX: "120deg",rotateY: "0deg",rotateZ: "0deg"},{transition: "transform 50ms ease 0",rotateX: "0deg",rotateY: "0deg",rotateZ: "0deg"}],stepsB: []},{type: "click",selector: ".spinner-destination",stepsA: [{opacity: 0,transition: "opacity 200 ease 0"},{wait: "400ms"},{opacity: 1,transition: "opacity 200 ease 0"}],stepsB: []}]}},
		{slug: "tabarrow",name: "TabArrow",value: {style: {},triggers: [{type: "click",selector: ".tab-triangle",stepsA: [{opacity: 1}],stepsB: []}]}},
		{slug: "tab-arrow-current",name: "Tab Arrow Current",value: {style: {},triggers: [{type: "click",selector: ".tab-triangle",descend: true,stepsA: [{opacity: 1,transition: "opacity 200 ease 0"}],stepsB: []}]}},
		{slug: "zoom-in-slide",name: "Zoom In Slide",value: {style: {opacity: 0,x: "0px",y: "-20px",z: "0px",scaleX: 0.5,scaleY: 0.5,scaleZ: 1},triggers: [{type: "slider",stepsA: [{wait: "100ms"},{opacity: 1,transition: "transform 600ms ease-out-back 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: [{opacity: 0,transition: "transform 200 ease 0, opacity 200 ease 0",x: "0px",y: "-20px",z: "0px",scaleX: 0.5,scaleY: 0.5,scaleZ: 1}]}]}},
		{slug: "search-x-reveal",name: "Search X Reveal",value: {style: {},triggers: [{type: "click",selector: ".nav-search-button-icon",descend: true,stepsA: [{display: "none"}],stepsB: [{display: "block"}]},{type: "click",selector: ".nav-search-button-close-icon",descend: true,stepsA: [{display: "block"}],stepsB: [{display: "none"}]}]}},
		{slug: "main-nav-dd-smooth-open",name: "Main Nav DD Smooth Open",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "opacity 350ms ease 0, height 350ms ease 0"}],stepsB: [{opacity: 0,height: "0px",transition: "height 100ms ease 0, opacity 100ms ease 0"}]},{type: "dropdown",selector: ".dd-link",stepsA: [{wait: "50ms"},{opacity: 1,transition: "opacity 350ms ease 0"}],stepsB: [{opacity: 0,transition: "opacity 100ms ease 0"}]}]}},
		{slug: "quick-search-reveal",name: "Quick Search Reveal",value: {style: {},triggers: [{type: "click",selector: ".quick-search-results-outer",stepsA: [{wait: "1500ms"},{opacity: 1,height: "auto",transition: "height 200 ease 0, opacity 200 ease 0"}],stepsB: []}]}},
		{slug: "new-dropdown",name: "New Dropdown",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "height 300ms ease 0, opacity 300ms ease 0"}],stepsB: [{height: "0px",transition: "height 100ms ease 0"}]}]}},
		{slug: "more-options",name: "More Options",value: {style: {},triggers: [{type: "click",stepsA: [{display: "none",transition: "transform 300ms ease 0"}],stepsB: []},{type: "click",selector: ".less-options-button",stepsA: [{display: "block",transition: "transform 300ms ease 0"}],stepsB: []},{type: "click",selector: ".locations-options-wrapper",stepsA: [{display: "block",transition: "transform 300ms ease 0"}],stepsB: []}]}},
		{slug: "hide-location-options",name: "Hide Location Options",value: {style: {display: "none"},triggers: []}},
		{slug: "less-options",name: "Less Options",value: {style: {display: "none"},triggers: [{type: "click",stepsA: [{display: "none",transition: "transform 300ms ease 0"}],stepsB: []},{type: "click",selector: ".more-options-button",stepsA: [{display: "block",transition: "transform 300ms ease 0"}],stepsB: []},{type: "click",selector: ".locations-options-wrapper",stepsA: [{display: "none",transition: "transform 300ms ease 0"}],stepsB: []}]}},
		{slug: "add-nav-bar-shadow-on-scroll",name: "Add Nav Bar Shadow On Scroll",value: {style: {},triggers: []}},
		{slug: "menu-card-hover",name: "Menu Card Hover",value: {style: {},triggers: [{type: "hover",selector: ".feature-card-bg-image",preserve3d: true,stepsA: [{transition: "transform 300ms ease 0",scaleX: 1.05,scaleY: 1.05,scaleZ: 1}],stepsB: [{transition: "transform 300ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}]},{type: "hover",selector: ".feature-card-heading",preserve3d: true,stepsA: [{transition: "transform 300ms ease 0",scaleX: 1.05,scaleY: 1.05,scaleZ: 1}],stepsB: [{transition: "transform 300ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}]}]}},
		{slug: "cb-image-hover",name: "CB Image Hover",value: {style: {},triggers: [{type: "hover",stepsA: [{transition: "transform 500ms ease 0",scaleX: 1.03,scaleY: 1.03,scaleZ: 1}],stepsB: [{transition: "transform 500ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}]}]}},
		{slug: "slide-caption-load",name: "Slide Caption Load",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "load",stepsA: [{opacity: 1,transition: "transform 750ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "cb1-reveal",name: "CB1 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",preload: true,stepsA: [{wait: "100ms"},{opacity: 1,transition: "transform 750ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "card-1-reveal",name: "Card 1 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "15%",stepsA: [{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "card-2-reveal",name: "Card 2 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "15%",stepsA: [{wait: "250ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "card-3-reveal",name: "Card 3 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "500ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "card-4-reveal",name: "Card 4 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "750ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "lower-heading-reveal",name: "Lower Heading Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "tab1-reveal",name: "Tab1 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "tab1-reveal-2",name: "Tab1 Reveal 2",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "250ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "tab1-reveal-3",name: "Tab1 Reveal 3",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "500ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "tab1-reveal-4",name: "Tab1 Reveal 4",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "750ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "tab1-reveal-5",name: "Tab1 Reveal 5",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "1000ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "close-alert",name: "Close Alert",value: {style: {},triggers: [{type: "click",selector: ".alert-wrap",stepsA: [{display: "none"}],stepsB: []},{type: "click",selector: ".spacer",stepsA: [{display: "none"}],stepsB: []}]}},
		{slug: "feature-text",name: "Feature Text",value: {style: {opacity: 0,x: "-40px",y: "0px",z: "0px"},triggers: [{type: "slider",stepsA: [{wait: "1000ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: [{opacity: 0,transition: "opacity 300ms ease 0"},{transition: "transform 200 ease 0",x: "-40px",y: "0px",z: "0px"}]}]}},
		{slug: "hide-mobile-header-on-load",name: "Hide Mobile Header On Load",value: {style: {height: "0px"},triggers: []}},
		{slug: "expand-search-bar",name: "Expand Search Bar",value: {style: {},triggers: []}},
		{slug: "minislideshow-load",name: "MiniSlideshow Load",value: {style: {opacity: 0,x: "0px",y: "200px",z: "0px"},triggers: [{type: "load",stepsA: [{wait: "450ms"},{opacity: 1,transition: "transform 1000ms ease-in-out 0, opacity 1000ms ease-in-out 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "intro-load",name: "Intro Load",value: {style: {opacity: 0,x: "0px",y: "200px",z: "0px"},triggers: [{type: "load",stepsA: [{wait: "450ms"},{opacity: 1,transition: "transform 1250ms ease 0, opacity 1500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "keep-find-flight-dd-open",name: "Keep Find Flight DD Open",value: {style: {},triggers: [{type: "hover",stepsA: [{opacity: 1,height: "auto"}],stepsB: []}]}},
		{slug: "reveal-view-all-flights-3",name: "Reveal View All Flights 3",value: {style: {},triggers: [{type: "click",selector: ".mini-flight-results-outer-wrap",stepsA: [{wait: "500ms"},{display: "block"},{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "height 200 ease 0, opacity 200 ease 0"}],stepsB: []}]}},
		{slug: "left-nav-load",name: "Left Nav Load",value: {style: {opacity: 0,x: "0px",y: "120px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "1000ms",title: "Wait"},{opacity: 1,transition: "transform 600ms ease 0, opacity 700ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "close-top-search",name: "Close Top Search",value: {style: {},triggers: [{type: "click",selector: ".mag-search-container",stepsA: [{height: "0px",transition: "height 500ms ease 0"}],stepsB: []}]}},
		{slug: "gay-block-load",name: "Gay Block Load",value: {style: {opacity: 0},triggers: [{type: "load",preload: true,stepsA: [{opacity: 1,transition: "opacity 500ms ease 0"}],stepsB: []}]}},
		{slug: "new-interaction",name: "New Interaction",value: {style: {},triggers: []}},
		{slug: "event-text-slider-over",name: "Event Text Slider Over",value: {style: {},triggers: [{type: "hover",selector: ".event-img-1",stepsA: [{width: "0px",transition: "width 450ms ease 0"}],stepsB: [{width: "30%",transition: "width 450ms ease 0"}]}]}},
		{slug: "event-text-slider-over-2",name: "Event Text Slider Over 2",value: {style: {},triggers: [{type: "hover",selector: ".event-img-2",stepsA: [{width: "0px",transition: "width 450ms ease 0"}],stepsB: [{width: "30%",transition: "width 450ms ease 0"}]}]}},
		{slug: "event-text-slider-over-3",name: "Event Text Slider Over 3",value: {style: {},triggers: [{type: "hover",selector: ".event-img-3",stepsA: [{width: "0px",transition: "width 450ms ease 0"}],stepsB: [{width: "30%",transition: "width 450ms ease 0"}]}]}},
		{slug: "event-text-slider-over-4",name: "Event Text Slider Over 4",value: {style: {},triggers: [{type: "hover",selector: ".event-img-4",stepsA: [{width: "0px",transition: "width 450ms ease 0"}],stepsB: [{width: "30%",transition: "width 450ms ease 0"}]}]}},
		{slug: "event-text-slider-over-5",name: "Event Text Slider Over 5",value: {style: {},triggers: [{type: "hover",selector: ".event-img-5",stepsA: [{width: "0px",transition: "width 450ms ease 0"}],stepsB: [{width: "30%",transition: "width 450ms ease 0"}]}]}},
		{slug: "event-text-slider-over-6",name: "Event Text Slider Over 6",value: {style: {},triggers: [{type: "hover",selector: ".event-img-6",stepsA: [{width: "0px",transition: "width 450ms ease 0"}],stepsB: [{width: "30%",transition: "width 450ms ease 0"}]}]}},
		{slug: "event-text-slider-over-7",name: "Event Text Slider Over 7",value: {style: {},triggers: [{type: "hover",selector: ".event-img-7",stepsA: [{width: "0px",transition: "width 450ms ease 0"}],stepsB: [{width: "30%",transition: "width 450ms ease 0"}]}]}}
	]);
	/* eslint-enable */
});

/* Webflow functions */
! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);
